import React, { useRef, useEffect, useState } from 'react';
import api from './common/Api';
import { useTranslation } from 'react-i18next';
import Storage, { StorageKey as SK } from './common/Storage';
import { AuthStatus, ErrCode } from './common/Types';
import ProtectedRoute from './Router/ProtectedRoute';
import QrPage, {stopQrLogin} from './pages/QrPage';
import 'react-js-dialog-box/dist/index.css';
import { house4, fnspay,bulb, latest, header, linkedin} from '../src/assets';
import { Dialog, DialogContent, DialogTitle, AlertTitle } from '@mui/material';
import OTPInput from "otp-input-react";
import totp_json from './Data/totpCode.json'
import token_json from './Data/userToken.json'
import { HashRouter as Router, Route, useNavigate } from 'react-router-dom';
import { useStores } from './common/Store';
import './App.css';
import style from './style';
import Dashboard from './pages/Dashboard';
import Deposits from './pages/Deposits';
import Loans from './pages/Loans';
import Notifications from './pages/Notifications';
import MorePages from './pages/More';
import TopNavbar from './components/Navbar';
import MainSub from './MainSub';
import Main from './Main';
import AppleLogo from './assets/App Store Button White 2.png'
import AndroidLogo from './assets/Google Play Button White.png'

function App() {
  // For login and app separate
  const Store = () => {
    const [isLogin, setisLogin] = useGlobalState("isLogin");
  };

  const host = 'https://api-fnspay.fnsmalaysia.com';
  // const clientKey = "700e9c996d954ae69281552d1f26113c";
  const clientKey = "488d178bfaca4aab8eca4eee98988186";
  const [isAuth, setIsAuth] = useState(false);
  const [username, setUsername] = useState('');
  const [getotp, setotp] = useState('');
  const [getTotp, setTotp] = useState('');
  const [QrOpen, setQrOpen] = React.useState(false);
  const [userKey, setUserKey] = React.useState('');
  const [otpUserKey, setOtpUserKey] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const [countDown, setCountDown] = useState(null);
  const [progressMsg, setProgressMsg] = React.useState(null);
  const [otpOpen, setOtpOpen] = React.useState(false);
  const [totpOpen, setTotpOpen] = React.useState(false);
  const [accessClientName, setAccessClientName] = useState('bsa');
  const [showAlert, setShowAlert] = useState(false);
  const { t } = useTranslation();
  const qrId = React.useRef(null);
  const qrUrl = React.useRef(null);
  const stompConnector = React.useRef(null);
  const isFinished = React.useRef(false);
  let fncClearInterval = useRef(null);
  const [masterClientKey, setMasterClientKey] = useState(api.MASTER_CLIENT_KEY);

  const isError = errMsg != null;
  const isProcess = (progressMsg != null) && (!isError);  

  //Countdown timer during authentication
  const customInterval = (time, onClose, timeoutMsg = null) => {
    let count = time;

    const id = setInterval(() => {
      count = count - 1;
      if (count) {
        setCountDown(count);
      } else {
        clear(true);
      }
    }, 1000);
  
    const clear = (isTimeout) => {
      console.log("clear event");
      if (id !== null) {
        if (stompConnector.current !== null) {
          console.log("stompConnector.current.deactivate();");
          stompConnector.current.deactivate();
        }
        clearInterval(id);
        if (isTimeout && timeoutMsg) {
          isFinished.current = true;
          setErrMsg(timeoutMsg);
        }
        if (onClose) {
          onClose();
        }
        setCountDown(null);
      }
    }

    return () => {
      clear(false)
    };
  }

  // UserKey Authentication
  const onLogin = (e) => {
    e.preventDefault();
    setErrMsg(null);
    if (username == null || username.length <= 0) {
      setErrMsg(t('LoginPage.LoginNoID'));
      return;
    }
    if (rememberMe) {
      // 로그인정보를 저장한다.
      Storage.setLocal(SK.LOGIN_INFO, { clientKey, username, rememberMe });
    } else {
      // 로그인정보를 삭제한다.
      Storage.removeLocal(SK.LOGIN_INFO);
    }
    onLoginProcess(username);
  };

  const onLoginProcess = (username, isOtpAuth = false) => {
    isFinished.current = false;
    setProgressMsg(t('AuthStatus.RequestAuth'));
    api.requestAuth(clientKey, username, isOtpAuth).then(res => {
      if (res.rtCode === ErrCode.RT_SUCCESS) {
        const authTimeRemaining = res.data.authTimeRemaining;
        const channelKey = res.data.channelKey;
        // 타이머 시작
        fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1,
        null,
        t('LoginPage.LoginTimeout'));
        setProgressMsg(t('AuthStatus.CreateChannel'));
        setProgressMsg(t('AuthStatus.SelectNodes'));
        setProgressMsg(t('AuthStatus.CheckDevice'));
        stompConnector.current = api.getStompWS(clientKey, username, channelKey)
        stompConnector.current.onConnect = ( data ) => {
          const subscribeCallBack = ( message ) => {
            const data = JSON.parse(message.body);
            const status = data.status;
            const statusMsg = t('AuthStatus.' + status);
            if( AuthStatus.isFinish( status ) ) {
              subscribeStomp.unsubscribe();
              setProgressMsg(null);
              isFinished.current = true;
              if (AuthStatus.isSuccess(status)) {
                if (isOtpAuth) {
                  setOtpOpen(true);
                } else { /* END if (isOtpAuth) { */
                  api.getAuthResult(clientKey, username, channelKey).then(data => {
                    if (data.rtCode === ErrCode.RT_SUCCESS) {
                      setIsAuth(true)
                      if(fncClearInterval.current !== null) {
                        fncClearInterval.current();
                      }
                      api.setToken(data.data);
                      console.log("token", data.data)
                      api.getMe().then(data => {
                        Storage.setSession(SK.LOGIN_USER_INFO, {data});
                        console.log("me",data)
                        sessionStorage.setItem('userType', data.data.userType)
                      }).catch(err => {
                        setErrMsg(err.rtMsg);
                      });
                    } else {
                      setErrMsg(api.getErrMsg(data.rtCode));
                      setProgressMsg(null);
                    }
                  }).catch(err => {
                    setErrMsg(err.rtMsg);
                  });
                }
              } else { /* END if (AuthStatus.isSuccess(status)) { */
                setErrMsg(statusMsg);
                setProgressMsg(null);
                if ( fncClearInterval.current !== null ) {
                  fncClearInterval.current();
                }
              }
            } else { /* END if( AuthStatus.isFinish( status ) ) {*/
              setProgressMsg(statusMsg);
            }
          };

          const subscribeStomp = stompConnector.current.subscribe('/user/queue', (message) => {
            if( message.command === "MESSAGE" ) {
              subscribeCallBack(message);
            } else {
              setErrMsg(t('AuthStatus.AuthFailed'));
              setProgressMsg(null);
            }
          });
        };

        stompConnector.current.onWebSocketClose = () => {
          console.log(" Close Web Socket Event Test");
          if( !isFinished.current ) {
            api.getAuthResult(clientKey, username, channelKey).then(data => {
              if (data.rtCode === ErrCode.RT_SUCCESS) {
                if(fncClearInterval.current !== null) {
                  fncClearInterval.current();
                }
                api.setToken(data.data);
                console.log("token", data.data)
                api.getMe().then(data => {
                  Storage.setSession(SK.LOGIN_USER_INFO, {data});
                  console.log("me",data)
                  sessionStorage.setItem('userType', data.data.userType)
                }).catch(err => {
                  setErrMsg(err.rtMsg);
                });
              } else {
                if( data.rtCode !== 2010 ) {
                  setErrMsg(api.getErrMsg(data.rtCode));
                  setProgressMsg(null);
                }
              }
            }).catch(err => {
              setErrMsg(err.rtMsg);
            });
          }
        };

        stompConnector.current.onDisconnect = () => {
          console.log("close connect event test");
        }
      } else {
        setErrMsg(api.getErrMsg(res.rtCode));
      }
    }).catch(err => {
      if (fncClearInterval.current != null) {
        fncClearInterval.current();
      }
      setErrMsg(err.rtMsg);
      setProgressMsg(null);
    });
  }

  const onCancel = () => {
    isFinished.current = true;

    api.deleteAuth(masterClientKey, otpUserKey ? otpUserKey : userKey)
        .then(data => {
            if (data.rtCode === ErrCode.RT_SUCCESS) {
                setProgressMsg(null);
                if (fncClearInterval.current !== null) { fncClearInterval.current(); }
            }
        }).catch(err => {
            setErrMsg(err.rtMsg);
        });
  }

  const { ds } = useStores();
  useEffect(() => {
    if (accessClientName !== undefined) {
      api.getAccessClientKey(accessClientName).then((data) => {
        setMasterClientKey(data);
      }).catch((err) => {
        setErrMsg(err.rtMsg);
      })
    }

    const info = Storage.getLocal(SK.LOGIN_INFO);
    if (info && info.rememberMe) {
      setUsername(info.userKey);
      setRememberMe(info.rememberMe);
    }

    //QR Authentication data passed
    if (sessionStorage.getItem('auth') === 'true') {
      setIsAuth(true)
    } else {
      setIsAuth(false)
    }
  },[])

  //QR Authentication
  const onQrLogin = () => {
    api.getLoginQrUrl(clientKey).then((data) => {
      qrId.current = data.qrId;
      qrUrl.current = data.qrUrl;
      setQrOpen(true);
    }).catch((err) => {
      setErrMsg(err.rtMsg);
    })
  }

  const onQrCancel = (isStop = false, userKey = null) => {
    setQrOpen(false);
    if (isStop) { stopQrLogin(qrId.current, userKey, setProgressMsg, setErrMsg); }
  }

  //OTP Authentication
  const otpSubmit = (e) => {
    e.preventDefault()
    let data = {
      "userKey": username,
      "clientKey": clientKey,
      "otpCode": getotp
    }

    api.postOTPKey(data).then((res) => {
      api.setToken(res.data);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        console.log("me", data)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    })
  }

  const totpSubmit = (e) => {
    e.preventDefault()
    console.log(getTotp)
    if (getTotp === totp_json.array.otpCode_1) {
    if (username === token_json.data.user_01.userKey) {
      api.setToken(token_json.data.user_01.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_02.userKey) {
      api.setToken(token_json.data.user_02.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_03.userKey) {
      api.setToken(token_json.data.user_03.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_04.userKey) {
      api.setToken(token_json.data.user_04.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_05.userKey) {
      api.setToken(token_json.data.user_05.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_06.userKey) {
      api.setToken(token_json.data.user_06.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else {
      alert('Invalid. Please check your User ID and TOTP code!')
    }
    } else if (getTotp === totp_json.array.otpCode_2) {
    if (username === token_json.data.user_01.userKey) {
      api.setToken(token_json.data.user_01.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_02.userKey) {
      api.setToken(token_json.data.user_02.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_03.userKey) {
      api.setToken(token_json.data.user_03.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_04.userKey) {
      api.setToken(token_json.data.user_04.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_05.userKey) {
      api.setToken(token_json.data.user_05.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else if (username === token_json.data.user_06.userKey) {
      api.setToken(token_json.data.user_06.token);
      setIsAuth(true);
      api.getMe().then(data => {
        Storage.setSession(SK.LOGIN_USER_INFO, { data });
        setIsAuth(true)
        sessionStorage.setItem('userType', data.data.userType)
      }).catch(err => {
        setErrMsg(err.rtMsg);
      });
    } else {
      alert('Invalid. Please check your User ID and TOTP code!')
    }
    } else if (getTotp === totp_json.array.otpCode_3) {
      if (username === token_json.data.user_01.userKey) {
        api.setToken(token_json.data.user_01.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_02.userKey) {
        api.setToken(token_json.data.user_02.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_03.userKey) {
        api.setToken(token_json.data.user_03.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_04.userKey) {
        api.setToken(token_json.data.user_04.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_05.userKey) {
        api.setToken(token_json.data.user_05.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_06.userKey) {
        api.setToken(token_json.data.user_06.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else {
        alert('Invalid. Please check your User ID and TOTP code!')
      }
    } else if (getTotp === totp_json.array.otpCode_4) {
      if (username === token_json.data.user_01.userKey) {
        api.setToken(token_json.data.user_01.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_02.userKey) {
        api.setToken(token_json.data.user_02.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_03.userKey) {
        api.setToken(token_json.data.user_03.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_04.userKey) {
        api.setToken(token_json.data.user_04.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_05.userKey) {
        api.setToken(token_json.data.user_05.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_06.userKey) {
        api.setToken(token_json.data.user_06.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else {
        alert('Invalid. Please check your User ID and TOTP code!')
      }
    } else if (getTotp === totp_json.array.otpCode_5) {
      if (username === token_json.data.user_01.userKey) {
        api.setToken(token_json.data.user_01.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_02.userKey) {
        api.setToken(token_json.data.user_02.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_03.userKey) {
        api.setToken(token_json.data.user_03.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_04.userKey) {
        api.setToken(token_json.data.user_04.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_05.userKey) {
        api.setToken(token_json.data.user_05.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else if (username === token_json.data.user_06.userKey) {
        api.setToken(token_json.data.user_06.token);
        setIsAuth(true);
        api.getMe().then(data => {
          Storage.setSession(SK.LOGIN_USER_INFO, { data });
          setIsAuth(true)
          sessionStorage.setItem('userType', data.data.userType)
        }).catch(err => {
          setErrMsg(err.rtMsg);
        });
      } else {
        alert('Invalid. Please check your User ID and TOTP code!')
      }
    }else {
      setIsAuth(false);
      alert('Invalid TOTP Code');
    }
  }

  const otpBox = () => {
    setOtpOpen(false)
    setotp("")
  }

  const TotpBox = () => {
    setTotpOpen(false)
    setTotp("")
  }

  console.log(progressMsg)  

  const SignIn =
    <div className="w-100 min-h-screen">
      <div className='z-0 absolute w-[100%] h-100'>
      <img className='relative object-fit w-[100%] h-100' src={header} />
    </div>
      <div className={`${style.paddingX} ${style.flexCenter}`}>
        <div className={`${style.boxWidth}`}>
      </div>
    </div>
    <div className={`bg-gray-200 flex flex-col items-center justify-center w-full min-h-screen overflow-hidden pt-30`}>    
      <div className={`xxs:px-2 xxs:w-[370px] z-10 flex rounded-2xl lg:w-full shadow-lg max-w-3xl py-6 px-6 items-center bg-gray-200`}>
        <div className='xxs:w-[370px] md:w-1/2 px-8 md:px-13'>
            <h2 className='font-bold text-3xl text-[#002D74]'>Login</h2>
            <form className='flex flex-col gap-1' onSubmit={onLogin}>
              <input className='p-2 mt-8 rounded-xl border' type='text' name='Username' placeholder='User ID' required onChange={(e) => setUsername(e.target.value)}></input>
              {/* countdown begins */}
              {countDown !== null ?
                <div style={{display:"flex", flexDirection:"row", justifyContent:"right", fontSize:"14px", height:"24px"}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"right"}}>
                        <p style={{margin:"none"}} variant='body1' className="">
                            {`Time : ${countDown} (s)`}
                        </p>&nbsp;
                        <button className='bg-[#7ac7eb] rounded-xl text-white hover:scale-105 duration-300'
                            size='small'
                            variant="contained"
                            color="primary"
                            onClick={onCancel}
                            style={{padding:"0px 10px 0px 10px", borderRadius:"4px", fontWeight:600}}
                        >Cancel
                        </button>
                    </div>
                </div> 
                :
                <div><div><p></p></div></div> 
              }
              {/* countdown ends */}
              <button type="submit" className='bg-[#7ac7eb] rounded-xl text-white py-2 hover:scale-105 duration-300' onSubmit={onLogin}>
                Login
              </button>
            </form>
            <div className='mt-3 grid grid-cols-3 items-center text-gray-400'>
              <hr className='border-gray-400'></hr>
              <p className='text-center text-sm'>OR</p>
              <hr className='border-gray-400'></hr>
            </div>
            
            <div className='flex flex-cols justify-center gap-1 mt-3'>
               <button type="submit" className='hover:bg-[#7ac7eb] rounded-xl text-[#002D74] hover:text-white py-2 hover:scale-105 duration-300 w-32 border-2 border-gray-300 hover:border-transparent' variant="contained" disabled={isProcess} onClick={isProcess ? onQrCancel : onQrLogin}>QR</button>
               <button type="submit" className='hover:bg-[#7ac7eb] rounded-xl text-[#002D74] hover:text-white py-2 hover:scale-105 duration-300 w-32 border-2 border-gray-300 hover:border-transparent' variant="contained" disabled={isProcess} onClick={() => setOtpOpen(true)}>OTP</button>
               {/* <button type="submit" className='hover:bg-[#7ac7eb] rounded-xl text-[#002D74] hover:text-white py-2 hover:scale-105 duration-300 w-32 border-2 border-gray-300 hover:border-transparent' onClick={() => setTotpOpen(true)}>TOTP</button> */}
            </div>

            <div className="mt-1 text-xs border-b border-gray-200 py-1 text-[#002D74]"></div>

            <div className='mt-3 text-xs flex justify-between items-center text-[#002D74]'>
              <p>Doesn't have an account? <a href="https://resource.fnsbsa.com/resource/BSA-FNSPay-Manual.pdf#page=4" target="_blank" className='font-bold text-[#002D74]' style={{textDecoration:"none"}}>Click here</a>
              </p>
            </div>

            <div className='mt-0 text-xs flex justify-between items-center text-[#002D74]'>
              <p>New device registration <a href="https://resource.fnsbsa.com/resource/BSA-FNSPay-Manual.pdf#page=8" target="_blank" rel="noreferrer" className='font-bold text-[#002D74]' style={{textDecoration:"none"}}>Click here</a>
              </p>
            </div>

            <div className='mt-0 text-xs flex justify-between items-center text-[#002D74]'>
              <p>Contact Support <a href='mailto:support.my@fnsvalue.co.kr' target="_blank" rel="noreferrer" className='font-bold text-[#002D74]' style={{textDecoration:"none"}}>Click here</a>
              </p>
            </div>

            <div className='mt-2 grid grid-cols-1 items-center text-gray-400'>
              <hr className='border-gray-400'></hr>
              <p className='text-center text-sm'>Download</p>
            </div>

            {/* Button with image */}
            <div className='flex flex-cols justify-center gap-1 mt-0'>
              <button type="submit" className='hover:bg-[#7ac7eb] rounded-xl text-[#002D74] hover:text-white py-2 hover:scale-105 duration-300 w-32 border-2 border-gray-300 hover:border-transparent download'>
              <a href='https://resource.fnsbsa.com/resource/BSA-FNSPay.apk' target="_blank" rel="noreferrer">
              <img src={AndroidLogo}/>
              </a>
              </button>
               {/* <button type="submit" className='hover:bg-[#7ac7eb] rounded-xl text-[#002D74] hover:text-white py-2 hover:scale-105 duration-300 w-32 border-2 border-gray-300 hover:border-transparent download'>
               <a href='https://apps.apple.com/us/app/bsa-itu-prod/id6451237527' target="_blank" rel="noreferrer">
               <img src={AppleLogo}/>
               </a>
               </button> */}
            </div>

            <div>
              <QrPage open={QrOpen}
                onClose={onQrCancel}
                title='Qr Authentication'
                qrId={qrId.current}
                qrUrl={qrUrl.current}
                onProgressMsg={setProgressMsg}
                onErrMsg={setErrMsg}
              />
            </div>
            <div>
              <Dialog
                open={otpOpen}
                onClose={otpBox}
                style={{ height: "400px" }}
              >
                <DialogTitle>OTP Authentication</DialogTitle>
                <DialogContent>
                  <form>
                    <label>Please check the OTP verification code on your mobile</label><br /><br />
                    <OTPInput value={getotp} onChange={setotp} autoFocus OTPLength={6} otpType="number" disabled={false} /><br /><br />
                    <button type="submit" className='button1' onClick={otpSubmit}>Confirm</button>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
            <div>
              <Dialog
                open={totpOpen}
                onClose={TotpBox}
                style={{ height: "400px" }}
              >
                <DialogTitle>TOTP Authentication</DialogTitle>
                <DialogContent>
                  <form>
                    <label>Please check the TOTP verification code on your mobile</label><br /><br />
                    <OTPInput value={getTotp} onChange={setTotp} autoFocus OTPLength={6} otpType="number" disabled={false} /><br /><br />
                    <button type="submit" className='button1' onClick={totpSubmit}>Confirm</button>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
        </div>
        <div className="md:block hidden w-1/2">
         <img className="rounded-2xl" src={latest} alt='house4'></img>
        </div>
      </div>
    </div>
  </div>

  const success =
    <div>
      <Dashboard username={username}/>
    </div>

  return (
    <Router>
      <Route path="/" exact component={Main}>
        <div>{isAuth ? success : SignIn }</div>
      </Route>
      <ProtectedRoute path="/deposits" component={Deposits} isAuth={isAuth}/>
      <ProtectedRoute path="/loans" component={Loans} isAuth={isAuth}/>
      <ProtectedRoute path="/notifications" component={Notifications} isAuth={isAuth}/>
      <ProtectedRoute path="/more" component={MorePages} isAuth={isAuth}/>

    </Router>
  )
}
export default App;
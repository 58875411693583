import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navbar.css';
import storage, {StorageKey as SK} from "../common/Storage";

function TopNavbar() {

  const handleLogout = () => {
    storage.removeSession(SK.ACCESS_TOKEN);
    sessionStorage.setItem('auth', 'false')
    sessionStorage.setItem('LoginUserInfo','')
    sessionStorage.setItem('userType','')
    window.location.reload()
  }

  return (
    <Navbar bg="" expand="lg" className='header'>
      <Container>
        <Navbar.Brand href="/"><img src={require('../assets/logo2.png')} style={{height: "80px"}} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <div onClick={handleLogout}><img src={require('../assets/logout.png')} style={{width: '50px', marginRight: '40px', cursor: 'pointer'}} title="Logout"/></div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
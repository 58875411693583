import React from 'react';
import axios from 'axios';

class PayAPI {
    HOST = 'http://localhost:8181/api/v1/demo';
    HOST_BSA = 'https://dev-app.fnsmalaysia.com/api/v1/demo';

    //to-do list:
    ////login function with auth
    ////register function with auth

    newUserRegister = (userKey) => {
        return new Promise((resolve, reject) => {
            axios.post(this.HOST+'/wallet/newuser', {userKey})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getWalletUser = (userKey) => {
        return new Promise((resolve, reject) => {
            axios.get(this.HOST+'/wallet/'+userKey)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getCard = (accountNum) => {
        return new Promise((resolve, reject) => {
            axios.get(this.HOST+'/card/'+accountNum)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

export default new PayAPI();
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function Loans() {
    return(
        <Container>
            <p class="font-weight-light" style={{marginTop: "1rem", marginBottom: "0"}}>Current moneyboxes</p>
            <Row>
                <Col>
                    <Card border="light">
                        <Card.Body>
                        <div>
                        <Card.Img variant="top" style={{height:100, width:"auto"}} src={require('../assets/21.png')} />  
                        <Card.Text className='d-inline-flex'>
                            New iPhone Pro Max
                        </Card.Text>
                        <Card.Title className='d-inline-flex' style={{float: "right"}}>1200.00 USD</Card.Title>
                        </div>
                        
                        <div>
                            <Card.Text className='d-inline-flex'>
                                650.27 USD
                            </Card.Text>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="button" class="btn btn-primary">Top - Up</button>&nbsp;&nbsp;
                            <button type="button" class="btn btn-success">Withdrawal</button>
                        </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Loans;
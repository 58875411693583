import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function Deposits() {
    const [state,setState] = React.useState({
        Amount: 0
    })

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        alert(JSON.stringify(state));

        setState({
            Amount: 0
        });

        setShow(false);
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    return(
        <Container className='deposits'>
            <p class="font-weight-light" style={{marginTop: "1rem", marginBottom: "0"}}>Current deposits</p>
            <Row>
                <Col>
                    <Card border="light">
                        <Card.Body>
                        <div>
                            <Card.Title className='d-inline-flex'>3000.00 USD</Card.Title>
                            <Card.Title className='d-inline-flex' style={{float: "right"}}>8%</Card.Title>
                        </div>
                        <div>
                            <Card.Text className='d-inline-flex'>
                                Sep 1 - Mar 1, 2022
                            </Card.Text>
                            <Card.Text className='d-inline-flex balances' style={{float: "right"}}>
                                + 60.57
                            </Card.Text>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="button" class="btn btn-primary">Top - Up</button>&nbsp;&nbsp;
                            <button type="button" class="btn btn-success" onClick={handleShow}>Transfer</button>
                        </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card border="light">
                        <Card.Body>
                        <div>
                            <Card.Title className='d-inline-flex'>1500.00 USD</Card.Title>
                            <Card.Title className='d-inline-flex' style={{float: "right"}}>10%</Card.Title>
                        </div>
                        <div>
                            <Card.Text className='d-inline-flex'>
                                Sep 1 2021 - Sep 1, 2022
                            </Card.Text>
                            <Card.Text className='d-inline-flex balances' style={{float: "right"}}>
                                + 150.00
                            </Card.Text>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="button" class="btn btn-warning">Extend</button>&nbsp;&nbsp;
                            <button type="button" class="btn btn-success">Withdrawal</button>
                        </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Current moneyboxes */}
            <p class="font-weight-light" style={{marginTop: "1rem", marginBottom: "0"}}>Current moneyboxes</p>
            <Row>
                <Col>
                    <Card border="light">
                        <Card.Body>
                        <div>
                        <Card.Img variant="top" style={{height:100, width:"auto"}} src={require('../assets/21.png')} />  
                        <Card.Text className='d-inline-flex'>
                            New iPhone Pro Max
                        </Card.Text>
                        <Card.Title className='d-inline-flex' style={{float: "right"}}>1200.00 USD</Card.Title>
                        </div>
                        <ProgressBar variant="success" now={60} />
                        <div>
                            <Card.Text className='d-inline-flex'>
                                650.27 USD
                            </Card.Text>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="button" class="btn btn-primary">Top - Up</button>&nbsp;&nbsp;
                            <button type="button" class="btn btn-success">Transfer</button>
                        </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Withdrawal */}
            <Modal dialogClassName='modal-lg' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Money Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3 d-flex" controlId="formBasicEmail">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control type="number" name="Amount" value={state.Amount} onChange={handleChange} placeholder="Enter amount" />
                            <Form.Text className="text-muted">
                            Fill in amount for withdrawal.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
                </Modal.Footer>
            </Modal>
        </Container>

        
    );
}

export default Deposits;
import React from "react";
import axios from "axios";
import i18next from "i18next";
import SockJS from "sockjs-client";
import * as StompJS from '@stomp/stompjs';
import storage, {StorageKey as SK} from './Storage';
import {AuthPlatform, ErrCode, User} from './Types';

class GuardianApi {

    HOST = 'https://api-fnspay.fnsmalaysia.com';
    // HOST = 'https://appbsa.tabgdc.com';
    // HOST = 'https://mpoc.fnsvalue.co.kr';
    // HOST = 'https://dev-app.fnsmalaysia.com';
    // MASTER_CLIENT_KEY = 'd64aacd8006e4377bb8584023683f444'; 
    // MASTER_CLIENT_KEY = "1daec78593a643e6b53ce9803ded5916";
    // MASTER_CLIENT_KEY = "700e9c996d954ae69281552d1f26113c";
    MASTER_CLIENT_KEY = "488d178bfaca4aab8eca4eee98988186";

    AUTH_PLATFORM = AuthPlatform.GuardianPortal;
    api = null;
    token = null;

    constructor() {
        this.token = storage.getSession(SK.ACCESS_TOKEN);

        this.api = axios.create({
            baseURL: this.HOST + '/api/v3',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });
        this.api.defaults.withCredentials = true;
        this.api.interceptors.request.use(config => {
            config.metadata = {startTime: new Date()};
            if (this.token != null && this.token.length > 0) {
                config.headers['Authorization'] = this.token;
            }
            const method = config.method.toUpperCase();
            //console.log(method, '-', config.baseURL + config.url, '[' + this.token + ']', '[' + config.headers['Content-Type'] + ']', config.params);
            return config;
        });
        this.api.interceptors.response.use(response => {
            const rtime = new Date() - response.config.metadata.startTime;
            //console.log('ResponseTime (', rtime, 'ms) :', response.data);
            const {rtCode} = response.data;
            if (rtCode !== ErrCode.RT_SUCCESS) {
                response.data.rtMsg = this.getErrMsg(rtCode);
                throw response.data;
            }

            if (response.data.token != undefined) {
                this.token = response.data.token;
            }

            return response;
        });
    }

    getErrMsg = (errCode) => {
        return i18next.t('Error.' + errCode);
    };

    setToken = (token) => {
        this.token = token;
        storage.setSession(SK.ACCESS_TOKEN, token);
    };

    hasToken = () => {
        return this.token !== null;
    };

    encodeParams = (data, nonNull = false) => {
        return Object
            .keys(data)
            .map(key => {
                    if (!nonNull || data[key] !== null) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
                    }
                    return null;
                }
            )
            .filter(value => value !== null)
            .join('&');
    };

    getClientCodes = () => {
        return new Promise((resolve, reject) => {
            this.api.get('/clients')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getWebSocket = (clientKey, userKey, channelKey) => {
        const query = this.encodeParams({clientKey, userKey, channelKey});
        const ws = new SockJS(this.HOST + '/ws/v3/auth/status?' + query);
        return ws;
    };

    getQrWebSocket = (qrId) => {
        const query = this.encodeParams({qrId});
        const ws = new SockJS(this.HOST + '/ws/v3/qr/status?' + query);
        return ws;
    };

    getLoginQrUrl = (clientKey) => {
        const url = `/qr/generate?${this.encodeParams({clientKey, authPlatform: this.AUTH_PLATFORM})}`;
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }


    getStompHOST = () => { return this.HOST.replace("http","ws"); }

    getStompWS = (clientKey, userKey, channelKey) => {
        const query = this.encodeParams( {clientKey, userKey, channelKey});
        const stomp = new StompJS.Client({
            brokerURL: this.getStompHOST() + '/ws/v3/app/websocket?' + query
            //debug: function (data){ console.log("debug => ", data); }
            , reconnectDelay: 500
        })
        stomp.activate();
        return stomp;
    }

    getStompQrWS = (qrId) => {
        const query = this.encodeParams({qrId});
        const stomp = new StompJS.Client({
            brokerURL: this.getStompHOST() + '/ws/v3/app/qr/websocket?' + query
            //debug: function (data){ console.log("debug => ", data); }
            , reconnectDelay: 500
        })
        stomp.activate();
        return stomp;
    }


    requestAuth = (clientKey, userKey, isOtpAuth = false) => {
        return new Promise((resolve, reject) => {
            this.api.post('/auth', {clientKey, userKey, isOtpAuth, authPlatform : this.AUTH_PLATFORM})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAuthResult = (clientKey, userKey, channelKey, isQrAuth =false) => {
        return new Promise((resolve, reject) => {
            const query = this.encodeParams({clientKey, userKey, channelKey,isQrAuth});
            this.api.get('/auth?' + query)
                .then(res => {
                    this.setToken(res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    deleteAuth = (clientKey, userKey, qrId = null) => {
        return new Promise((resolve, reject) => {
            this.api.delete('/auth', {data: {clientKey: clientKey, userKey: userKey, qrId: qrId}})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    //BSA get Me Data
    getMe = () => {
        return new Promise((resolve, reject) => {
            this.api.get('/me')
                .then(res => {
                    let user = new User(res.data.data);

                    
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getClientList = (clientName, page = 0, size = 0) => {
        let url = '/admin/clients?' + this.encodeParams({page, size, clientName}, true);
        return new Promise((resolve, reject) => {
            this.api.get(url)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getClientDetail = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get('/admin/clients/' + clientKey)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    postOTPKey = (data) => {
        return new Promise((resolve, reject) => {
            this.api.post('otp/user/verify', data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };

    getAccessClientKey = (clientName) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/clientKey/${clientName}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getAuthHistory = (clientKey, userKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/users/${userKey}/history`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getClientAuthHistory = (clientKey) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/admin/clients/${clientKey}/auth/history?page=417&size=20`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

export default new GuardianApi();
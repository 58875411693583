import React from 'react';
import { makeObservable, observable, computed, action, flow } from "mobx"
import { MobXProviderContext } from "mobx-react";
import useGlobalState from '@vighnesh153/use-global-state';

// class Store{
//     useGlobalState = {
//         isLogin : false,
//     };
//     constructor(isLogin){
//         makeObservable(this, {
//             isLogin: observable,
//             setLogin: action
//         })
//         this.isLogin = isLogin
//     }

//     setLogin = (isLogin)=>{
//         this.isLogin = isLogin
//     }

// }

// export default new Store();
// export function useStores() {
//     return React.useContext(MobXProviderContext);
// }

class Store{
    isLogin = false

    constructor(isLogin){
        makeObservable(this, {
            isLogin: observable,
            setLogin: action
        })
        this.isLogin = isLogin
    }

    setLogin = (isLogin)=>{
        this.isLogin = isLogin
    }

}

export default new Store();
export function useStores() {
    return React.useContext(MobXProviderContext);
}
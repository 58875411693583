import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import {QrLogoImageSrc} from '../components/Logo'
import { Box } from '@mui/system'
import QRCode from 'qrcode.react'
import React, {useEffect, useRef, useState} from 'react'
import Api from '../common/Api'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import {AuthStatus, ErrCode} from '../common/Types';
import storage, {StorageKey as SK} from "../common/Storage";
import { BrowserRouter, Router, Route, Routes, Navigate } from 'react-router-dom';

export function stopQrLogin(qrId, userKey, onProgressMsg, onErrMsg) {
    Api.deleteAuth(Api.MASTER_CLIENT_KEY, userKey, qrId)
        .then(data => {
            if (data.rtCode === ErrCode.RT_SUCCESS) {
                onProgressMsg(null);
            }
        }).catch(err => {
        onErrMsg(err.rtMsg);
    });
}

const QrPage = ({open, onClose, title, qrId, qrUrl, onProgressMsg, onErrMsg}) => {

    const {t} = useTranslation();
    // const {ds} = useStore();
    const fncClearInterval = useRef(null);

    const [progressMsg, setProgressMsg] = useState(null);
    const [countDown, setCountDown] = useState(null);

    const userKey = useRef(null);
    const channelKey = useRef(null);

    const stompConnector = React.useRef(null);
    const isFinished = React.useRef(false);


    const customInterval = (time, onClose, timeoutMsg = null, ws = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {
            if (id !== null) {

                if( stompConnector.current !== null ) {
                    console.log("stompConnector.current.deactivate()");
                    stompConnector.current.deactivate();
                }

                clearInterval(id);

                if (isTimeout && timeoutMsg) {
                    isFinished.current = true;
                    console.log("timeoutMsg => " , timeoutMsg);
                    onErrMsg(timeoutMsg);
                }

                if (onClose) { onClose(); }

                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    const isProcess = progressMsg != null;

    function qrCancel(isStop = false) {
        isFinished.current = true;
        if (onClose) {
            onClose(isStop, userKey);
        }
        fncClearInterval.current();
    }

    useEffect(() => {
        if (open) {
            isFinished.current = false;
            setProgressMsg(null);
            onErrMsg(null);

            const authTimeRemaining = 60000;

            //const stomp = api.getStompQrWS(qrId);
            stompConnector.current = Api.getStompQrWS(qrId);
            console.log('qrId-qrPage', qrId)
            console.log('qrUrl-qrPage', qrUrl)
            // 타이머 시작
            fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1, onClose, t('LoginPage.LoginTimeout'));

            stompConnector.current.onConnect = ( data ) => {
                const onClose = () => {
                    subscribeStomp.unsubscribe();
                    fncClearInterval.current();
                }

                const subscribeCallBack = (message) => {
                    const data = JSON.parse(message.body);
                    const status = data.status;
                    const type = data.type;
                    const statusMsg = t('AuthStatus.' + status);
                    const clientKey = Api.MASTER_CLIENT_KEY;

                    if (type !== undefined && type === "data") {
                        userKey.current = data.userKey;
                        channelKey.current = data.channelKey;
                    }

                    if (status === AuthStatus.RequestAuth) {

                        setTimeout( ()=> {setProgressMsg(t('AuthStatus.CreateChannel'));} , 1000);
                        setTimeout( ()=> {setProgressMsg(t('AuthStatus.SelectNodes'));} , 1000);

                    } else if (AuthStatus.isFinish(status)) {

                        subscribeStomp.unsubscribe();
                        isFinished.current = true;

                        open = false;
                        setProgressMsg(null);

                        if (AuthStatus.isSuccess(status)) {

                            Api.getAuthResult(clientKey, userKey.current, channelKey.current, true)
                                .then(data => {
                                    if (data.rtCode === ErrCode.RT_SUCCESS) {

                                        if(fncClearInterval.current !== null) {
                                            fncClearInterval.current();
                                        }
                                        
                                        Api.setToken(data.data)
                                        Api.getMe()
                                        .then((data) => {
                                            console.log(data)
                                            // setLogin(true)
                                            sessionStorage.setItem('auth', 'true')
                                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                                            sessionStorage.setItem('userType', data.data.userType)
                                            window.location.reload()
                                        })
                                    } else {
                                        onErrMsg(Api.getErrMsg(data.rtCode));
                                        setProgressMsg(null);
                                    }

                                })
                                .catch(err => {
                                    onErrMsg(err.rtMsg);
                                });
                        } else {
                            onErrMsg(statusMsg);
                            if ( fncClearInterval.current !== null ) {
                                fncClearInterval.current();
                            }
                        }

                    } else {
                        setProgressMsg(statusMsg);
                    }
                }


                const subscribeStomp = stompConnector.current.subscribe('/user/queue', (message) => {
                    if (message.command === "MESSAGE") {
                        subscribeCallBack(message);
                    } else {
                        onProgressMsg(t('AuthStatus.AuthFailed'));
                    }
                });

                stompConnector.current.onWebSocketClose = () => {
                    console.log(" Close Web Socket Event Test");

                    if( !isFinished.current ) {
                        Api.getAuthResult(Api.MASTER_CLIENT_KEY, userKey.current, channelKey.current, true)
                            .then(data => {
                                if (data.rtCode === ErrCode.RT_SUCCESS) {

                                    if(fncClearInterval.current !== null) {
                                        fncClearInterval.current();
                                    }

                                    Api.setToken(data.data);
                                    Api.getMe()
                                        .then(data => {
                                            sessionStorage.setItem('auth', 'true')
                                            ds.setLogin(true);
                                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                                            sessionStorage.setItem('userType', data.data.userType)
                                            console.log('qrpage',data.data.userType)
                                            window.location.reload()
                                        })
                                        .catch(err => {
                                            onErrMsg(err.rtMsg);
                                        });
                                } else {
                                    onErrMsg(Api.getErrMsg(data.rtCode));
                                    setProgressMsg(null);
                                }

                            })
                            .catch(err => {
                                onErrMsg(err.rtMsg);
                            });
                    }

                };

                stompConnector.current.onDisconnect = () => {
                    console.log("close connect event test");
                }
            }
        }


    }, [open])

  return (
    <Dialog
        open={open}
        onClose={()=> qrCancel(true, userKey)}
        aria-labelledby='QR'
        maxWidth='md'
        className="dialog-outer"
    >
        <DialogTitle className="dialog-title">QR Authentication</DialogTitle>
        <DialogContent className='content'>
            <Box className="dialog-content-box">
                <QRCode className='qrCanvas' value={qrUrl} size={128} imageSettings={{src: QrLogoImageSrc(), width: 33, height: 38}} />
            </Box>
            <Typography>Time Remain: {countDown}s</Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => qrCancel(true, userKey)}>Cancel</Button>
        </DialogActions>
    </Dialog>
  )
}

export default QrPage
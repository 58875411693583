import React from 'react'
import Sidebar from './components/Sidebar';
import './Main.css';

function Main() {
  return (
    <div className="App">
        <div className="AppGlass">
        <Sidebar/>
      </div>
     </div>
  )
}

export default Main
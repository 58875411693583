import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function MorePages() {
    return(
        <Container>
            More features will be added soon..
        </Container>
    );
}

export default MorePages;
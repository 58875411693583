import React, { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import PayAPI from '../common/PayAPI';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { FormControl } from 'react-bootstrap';
import Api from '../common/Api';
import {AuthStatus, OtpType, ErrCode, UserStatus} from '../common/Types';
import Storage, {StorageKey as SK} from '../common/Storage';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import walletFill from '../assets/wallet-fill.svg';
import creditCard from '../assets/credit-card.svg';
import arrowRepeat from '../assets/arrow-repeat.svg';
import TopNavbar from '../components/Navbar';
import '../components/Navbar';
import image1 from '../assets/cardwallpaper.jpg';
import { cardsData } from '../Data/Data';
import './Dashboard.css';


function Dashboard(props) {
    const [state,setState] = React.useState({
        walletBal: 1000, creditBal: 6000, debitBal: 10000, creditOutstanding: 0
    });
    const [data, setData] = useState({ Name: '', AccountNo: '' });
    const [topup, setTopup] = useState({ Amount: 0, Topup: 0, Recipient: '', Type: '', MNO: '' });
    const [userKey, setUserKey] = useState(props.username);
    const [masterClientKey, setMasterClientKey] = useState(Api.MASTER_CLIENT_KEY);
    const [otpOpen, setOtpOpen] = React.useState(false);
    let fncClearInterval = useRef(null);
    const [countDown, setCountDown] = useState(null);
    const [errMsg, setErrMsg] = React.useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');
    const historyList = []

    const [cardList, setCardList] = useState([
        {
            Name: userKey,
            Issuer: 'FNSPay',
            Type: 'Credit Card',
            //cardNum: 22334455667788
            cardNum: '**** **** 7788'
        },
        {
            Name: userKey,
            Issuer: 'FNSPay',
            Type: 'Debit Card',
            // cardNum: 88776655443322
            cardNum: '**** **** 3322'
        },
        {
            Name: userKey,
            Issuer: 'FNSPay',
            Type: 'Topup Card',
            cardNum: 55662233448877
        }
    ]);
    const [paymentFor, setPaymentFor] = useState('creditCard');
    const isFinished = React.useRef(false);
    const [progressMsg, setProgressMsg] = React.useState(null);
    const [history, setHistory] = useState(historyList);
    const [card, setCard] = useState(cardList);
    const stompConnector = React.useRef(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // setUserKey(props.username);
        getMe();
    },[])

    const getMe = () => {
        Api.getMe().then((data)=> {
            setUserKey(data.data.userKey);
        });
    }

// Request for Auth
const submitAuth = (selectArr, target, p_userKey, isOtpAuth = false) => {
    handleShow(); 
    isFinished.current = false;
    setProgressMsg('AuthStatus.RequestAuth');

    const sType = selectArr[2];

    Api.requestAuth(masterClientKey, p_userKey, isOtpAuth)
        .then(res => {
            if (res.rtCode === ErrCode.RT_SUCCESS) {
                const authTimeRemaining = res.data.authTimeRemaining;
                const channelKey = res.data.channelKey;

                // 타이머 시작
                fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1,
                    null,
                    'LoginPage.LoginTimeout');

                setProgressMsg('AuthStatus.CreateChannel');
                setProgressMsg('AuthStatus.SelectNodes');
                setProgressMsg('AuthStatus.CheckDevice');

                stompConnector.current = Api.getStompWS(masterClientKey, p_userKey, channelKey)
                stompConnector.current.onConnect = ( data ) => {
                    const subscribeCallBack = ( message ) => {
                        const data = JSON.parse(message.body);
                        const status = data.status;
                        const statusMsg = 'AuthStatus.' + status;

                        if( AuthStatus.isFinish( status ) ) {
                            subscribeStomp.unsubscribe();
                            setProgressMsg(null);
                            isFinished.current = true;

                            if (AuthStatus.isSuccess(status)) {
                                if (isOtpAuth) {
                                    setOtpOpen(true);
                                } 
                                else { /* END if (isOtpAuth) { */
                                    Api.getAuthResult(masterClientKey, p_userKey, channelKey)
                                        .then(data => {
                                            if (data.rtCode === ErrCode.RT_SUCCESS) {
                                                if(fncClearInterval.current !== null) {
                                                    fncClearInterval.current();
                                                }
                                                Api.setToken(data.data);
                                                console.log("token", data.data);
                                                Api.getMe()
                                                    .then(data => {
                                                        Storage.setSession(SK.LOGIN_USER_INFO, {data});
                                                        console.log("me",data)
                                                        sessionStorage.setItem('userType', data.data.userType)
                                                        
                                                        if(target === 'Payment - Credit Card'){
                                                            {payToCC(selectArr, target)}
                                                        } else if(target === 'Payment - Telcos'){
                                                            {payToTelcos(selectArr, target)}
                                                        }
                                                        else{
                                                            {transactionSubmit(selectArr, target)}
                                                        }
                                                        
                                                        handleClose();
                                                        
                                                        // alert('Authentication approved');
                                                    })
                                                    .catch(err => {
                                                        setErrMsg(err.rtMsg);
                                                    });
                                            } else {
                                                setErrMsg(Api.getErrMsg(data.rtCode));
                                                setProgressMsg(null);
                                            }
                                        })
                                       .catch(err => {
                                            setErrMsg(err.rtMsg);
                                        });
                                }
                            } else { /* END if (AuthStatus.isSuccess(status)) { */
                                setErrMsg(statusMsg);
                                setProgressMsg(null);
                                if ( fncClearInterval.current !== null ) {
                                    fncClearInterval.current();
                                }
                                handleClose();
                                // alert('Authentication rejected');
                            }
                        } 
                        else { /* END if( AuthStatus.isFinish( status ) ) {*/
                            setProgressMsg(statusMsg);
                        }
                    };

                    const subscribeStomp = stompConnector.current.subscribe('/user/queue', (message) => {
                        if( message.command === "MESSAGE" ) {
                            subscribeCallBack(message);
                        } 
                        else {
                            // setErrMsg(t('AuthStatus.AuthFailed'));
                            setProgressMsg(null);
                        }
                    });
                };

            stompConnector.current.onWebSocketClose = () => {
                console.log(" Close Web Socket Event Test");

                if( !isFinished.current ) {
                    Api.getAuthResult(masterClientKey, p_userKey, channelKey)
                        .then(data => {
                            if (data.rtCode === ErrCode.RT_SUCCESS) {
                                if(fncClearInterval.current !== null) {
                                    fncClearInterval.current();
                                }

                                Api.setToken(data.data);
                                console.log("token", data.data)
                                Api.getMe()
                                    .then(data => {
                                        Storage.setSession(SK.LOGIN_USER_INFO, {data});
                                        console.log("me",data)
                                        sessionStorage.setItem('userType', data.data.userType)
                                    })
                                    .catch(err => {
                                        setErrMsg(err.rtMsg);
                                });
                            } else {
                                if( data.rtCode !== 2010 ) {
                                    setErrMsg(Api.getErrMsg(data.rtCode));
                                    setProgressMsg(null);
                                }
                            }
                            return data.data;
                        })
                        .catch(err => {
                            setErrMsg(err.rtMsg);
                        });
                }
            };

            stompConnector.current.onDisconnect = () => {
                console.log("close connect event test");
            }


        } else {
            setErrMsg(Api.getErrMsg(res.rtCode));
        }
    })
    .catch(err => {
        if (fncClearInterval.current != null) {
            fncClearInterval.current();
        }
        setErrMsg(err.rtMsg);
        setProgressMsg(null);
    });
}

const customInterval = (time, onClose, timeoutMsg = null) => {
    let count = time;

    const id = setInterval(() => {
        count = count - 1;
        if (count) {
            setCountDown(count);
        } else {
            clear(true);
        }
    }, 1000);


    const clear = (isTimeout) => {

        console.log("clear event");

        if (id !== null) {
            if( stompConnector.current !== null ) {
                console.log("stompConnector.current.deactivate();");
                stompConnector.current.deactivate();
            }

            clearInterval(id);
            if (isTimeout && timeoutMsg) {
                isFinished.current = true;

                setErrMsg(timeoutMsg);
            }
            if (onClose) {
                onClose();
            }
            setCountDown(null);
        }
    }

    return () => {
        clear(false)
    };
}
//End Request for Auth

// transactionSubmit Process
const transactionSubmit = (selectArr, target) => {
    const sName = selectArr[0];
    const sIssuer = selectArr[1];
    const sType = selectArr[2];
    const sCardNum = selectArr[3];
    const sAmount = topup.Amount;

    if (sType === 'Credit Card'){
        setState({
            ...state,
            walletBal: Number(state.walletBal) + Number(sAmount),
            creditBal: Number(state.creditBal) - Number(sAmount),
            creditOutstanding: Number(state.creditOutstanding) + Number(sAmount)
        });

        console.log("Credit Balance: "+state.creditBal);
        console.log("Credit Outstanding: "+state.creditOutstanding);
    } else if(sType === 'Debit Card'){
        setState({
            ...state,
            walletBal: Number(state.walletBal) + Number(sAmount),
            debitBal: Number(state.debitBal) - Number(sAmount)
        });

        console.log("Debit Balance: "+state.debitBal);
    } else{
        setState({
            ...state,
            walletBal: Number(state.walletBal) - Number(sAmount)
        });
    }

    if(target === 'Transfer'){
        setHistory(historyList => [...historyList, {
            Name: "Transfer to "+ selectedOption2,
            Amount: Number(sAmount),
            Type: sType + " | " + sIssuer,
            Target: target
        }]);
    } else{
        setHistory(historyList => [...historyList, {
            Name: "To Wallet(Me)",
            Amount: Number(sAmount),
            Type: sType + " | " + sIssuer,
            Target: target
        }]);
    }
    

    setTopup({
        ...topup,
        Amount: 0,
        CVV: 0
    })
}
// End transactionSubmit Process

// payToCC Process
const payToCC = (selectArr, target) => {
    const sName = selectArr[0];
    const sIssuer = selectArr[1];
    const sType = selectArr[2];
    const sCardNum = selectArr[3];
    const sAmount = topup.Amount;

    const selectArr2 = selectedOption2.split(",");
    const rName = selectArr2[0];
    const rIssuer = selectArr2[1];
    const rType = selectArr2[2];
    const rCardNum = selectArr2[3];

    setState({
        ...state,
        creditBal: Number(state.creditBal) + Number(sAmount),
        creditOutstanding: Number(state.creditOutstanding) - Number(sAmount),
        debitBal: Number(state.debitBal) - Number(sAmount)
    });

    console.log("Credit Balance: "+state.creditBal);
    console.log("Credit Outstanding: "+state.creditOutstanding);
    console.log("Debit Balance: "+state.debitBal);

    setHistory(historyList => [...historyList, {
        Name: "To "+rName+" ("+rCardNum+") Credit Card",
        Amount: Number(sAmount),
        Type: sType + " | " + sIssuer,
        Target: target
    }]);

    setTopup({
        ...topup,
        Amount: 0,
        CVV: 0
    })
}
// End payToCC Process

// payToTelcos
const payToTelcos = (selectArr, target) => {
    const sName = selectArr[0];
    const sIssuer = selectArr[1];
    const sType = selectArr[2];
    const sCardNum = selectArr[3];
    const sAmount = topup.Amount;

    if (sType === 'Credit Card'){
        setState({
            ...state,
            creditBal: Number(state.creditBal) - Number(sAmount),
            creditOutstanding: Number(state.creditOutstanding) + Number(sAmount)
        });

        console.log("Credit Balance: "+state.creditBal);
        console.log("Credit Outstanding: "+state.creditOutstanding);
    } else if(sType === 'Debit Card'){
        setState({
            ...state,
            debitBal: Number(state.debitBal) - Number(sAmount)
        });

        console.log("Debit Balance: "+state.debitBal);
    } else{
        setState({
            ...state,
            walletBal: Number(state.walletBal) - Number(sAmount)
        });
    }

    setHistory(historyList => [...historyList, {
        Name: "To "+topup.MNO+" Telcos",
        Amount: Number(sAmount),
        Type: sType + " | " + sIssuer,
        Target: target
    }]);

    setTopup({
        ...topup,
        Amount: 0,
        CVV: 0,
        MNO: 0
    })
}


//Top-Up and Payment Transactions
    function changeTopup(evt) {
        const value = evt.target.value;

        setTopup({
            ...topup,
            [evt.target.name]: value
        });
    }

    // const cardStyle = {
    //     width: "25rem",
    //     height: "14rem",
    //     backgroundImage:`url(${image1})`,
    //     backgroundSize: "cover",
    //     backgroundPosition: "center",
    //     backgroundBlendMode: "darken",
    //     borderRadius: '20px',
    //     alignItem: 'Center',
    //   };
    
    const titleStyle = {
        fontSize: '25px',
        color: 'white',
        fontWeight: 'bold'
    };

    const textStyle ={
        // textShadow: '2px 2px #D5D5D5',
        fontWeight: 'bold',
        color: 'white',
        width: '15rem'
    };

    const walletBalanceStyle = {
        fontSize: '15px',
        color : 'white',
        fontWeight: 'bold',
        alignItem: 'left',
        float: 'right'
    }

    //Select Option
    const changeSelect = (event) => {
        setSelectedOption(
            event.target.value
        );
    };

    const changeSelect2 = (event) => {
        setSelectedOption2(
            event.target.value
        );
    };

    const walletStyle ={
        background: 'linear-gradient(to left,#a3cbfb , #c5d7ee )',
        border: 'unset'
    }

    const walletButtonStyle ={
        background: 'linear-gradient(to left, rgb(0 7 14), rgb(6 73 158))',
        fontWeight: 'bold'
    }

    const historyStyle ={
        // background: 'linear-gradient(to left,#a3cbfb , #c5d7ee )',
        background: 'linear-gradient(to left, rgb(216 229 247), rgb(104 167 243))',
        display: 'flex',    
        justifyContent: 'center',
        alignItemss: 'center',
        border: 'unset'
    }
    const paymentStyle ={
        background: 'linear-gradient(to left,#a3cbfb , #c5d7ee )',
    }
    const transferStyle ={
        background: 'linear-gradient(to left,#a3cbfb , #c5d7ee )',
    }

    const handleSubmit = (target) => {
        const selectArr = selectedOption.split(",");

        if(target === 'Payment - Credit Card' || 'Payment - Telcos'){
            console.log(target);
        }
        submitAuth(selectArr,target,userKey);
    }

    return(
        <>
        {/* <img src={image1}/> */}
        <TopNavbar />
        <Container className='dashboard'>
            {/* Row: My Wallet */}
            <Row>
            {cardList.map((item,index)=>(
                <Col > 
                    <Card key={index} className='cardStyle'>
                        <Card.Body>
                            <Card.Title style={titleStyle}>{item.Type}</Card.Title>
                            <Card.Text>
                                <Form style={textStyle}>
                                    <Row sm={7}>   
                                    <Col><Form.Label>FNSPay</Form.Label></Col><Col sm={6}><Form.Label style ={walletBalanceStyle}><b>
                                        {item.Type === 'Credit Card' ? item.cardNum 
                                        : item.Type === 'Debit Card' ? item.cardNum
                                        : item.Type === 'Topup Card' ? 'RM'+parseFloat(state.walletBal) 
                                        : 0}
                                    </b></Form.Label></Col>
                                    </Row>
                                    <Row sm={12}>
                                        <Col><Form.Label>{props.username}</Form.Label></Col>
                                    </Row>
                                    
                                </Form>
                            </Card.Text>
                        </Card.Body>
                    
                    </Card>
                    
                </Col>
                ))}
            </Row>
            {/* Row: My Wallet Ends */}
            <hr />

            {/* Transactions Home */}
            <Tab.Container id="justify-tabs-example">
                <Row>
                    <Col className='deduct-type'>
                    <Nav variant="pills" className="mb-3" justify>
                        <Nav.Item className='receive shadow rounded'>
                        <Nav.Link eventKey="first"><img src={walletFill} style={{width: '40px', display: 'unset'}} title='Wallet' />&nbsp;<h4>Wallet</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="payment shadow rounded">
                        <Nav.Link eventKey="second"><img src={creditCard} style={{width: '40px', display: 'unset'}} title='Payment' />&nbsp;<h4>Payment</h4></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="transfer shadow rounded">
                        <Nav.Link eventKey="third"><img src={arrowRepeat} style={{width: '40px', display: 'unset'}} title='Transfer' />&nbsp;<h4>Transfer</h4></Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                </Row>
                <Row className="transactions">
                    <Col className='deduct-type'>
                        <Tab.Content>
                            {/* Wallet Home */}
                            <Tab.Pane eventKey="first">
                                <Card style ={walletStyle}>
                                    <Card.Title>Wallet <sub>(topup by)</sub></Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Form.Group className="mb-3 d-flex" controlId="creditAmount">
                                                <Form.Label className='d-flex align-items-center'>Amount</Form.Label>&nbsp;
                                                <Form.Control type="number" name="Amount" value={topup.Amount} onChange={changeTopup} placeholder="Enter amount" />&nbsp;
                                            </Form.Group>
                                            <Form.Group className="mb-3 d-flex" controlId="creditCardNum">
                                                <Form.Label className='d-flex align-items-center'>Select Card</Form.Label>&nbsp;
                                                <Form.Select aria-label="Default select example" onChange={changeSelect}>
                                                    <option>Select your card</option>
                                                    {cardList.map((res) => (
                                                        res.Type !== 'Topup Card' ? (
                                                        <option 
                                                            value={[res.Name,
                                                                res.Issuer,
                                                                res.Type,
                                                                res.cardNum,
                                                                res.Amount]} 
                                                            onChange={changeSelect}>
                                                            {res.Type} | {res.Name} | {res.cardNum}
                                                        </option>
                                                        ) : null))}
                                                </Form.Select>
                                            </Form.Group>
                                            <Button style ={walletButtonStyle}variant="primary" onClick={() => handleSubmit('Wallet')}>
                                                Submit
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            {/* End Wallet Home */}
                            {/* Payment Home */}
                            <Tab.Pane eventKey="second">
                                <Card style={paymentStyle}>
                                    <Card.Title>Payment <sub>(for)</sub></Card.Title>
                                    <Card.Body>
                                        <Tab.Container id="justify-tabs-example">
                                            <Row>
                                                <Col className='deduct-type'>
                                                <Nav variant="pills" className="mb-3" justify>
                                                    <Nav.Item className='receive shadow rounded'>
                                                    <Nav.Link eventKey="payment-credit"><img src={walletFill} style={{width: '40px', display: 'unset'}} title='Wallet' />&nbsp;<h4>Credit Card</h4></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="payment shadow rounded">
                                                    <Nav.Link eventKey="payment-telcos"><img src={creditCard} style={{width: '40px', display: 'unset'}} title='Payment' />&nbsp;<h4>Telcos</h4></Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Tab.Content>
                                                        {/* Payment - Credit Card */}
                                                        <Tab.Pane eventKey="payment-credit">
                                                            <Card style={paymentStyle}>
                                                                <Card.Title>Credit Card <sub>(by debit only)</sub></Card.Title>
                                                                <Card.Body>
                                                                    <Form>
                                                                        <Form.Group className="mb-3 d-flex" controlId="creditCardNum">
                                                                            <Form.Label className='d-flex align-items-center'>Paid To</Form.Label>&nbsp;
                                                                            <Form.Select aria-label="Default select example" onChange={changeSelect2}>
                                                                                <option>Select your card</option>
                                                                                {cardList.map((res) => (
                                                                                    res.Type === 'Credit Card'  ? (
                                                                                    <option 
                                                                                        value={[res.Name,
                                                                                            res.Issuer,
                                                                                            res.Type,
                                                                                            res.cardNum,
                                                                                            res.Amount]} 
                                                                                        onChange={changeSelect2}>
                                                                                        {res.Type} | {res.Name} | {res.cardNum}
                                                                                    </option>
                                                                                    ) : null
                                                                                        ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3 d-flex" controlId="creditAmount">
                                                                            <Form.Label className='d-flex align-items-center'>Amount</Form.Label>&nbsp;
                                                                            <Form.Control type="number" name="Amount" value={topup.Amount} onChange={changeTopup} placeholder="Enter amount" />&nbsp;
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3 d-flex" controlId="creditCardNum">
                                                                            <Form.Label className='d-flex align-items-center'>Paid By</Form.Label>&nbsp;
                                                                            <Form.Select aria-label="Default select example" onChange={changeSelect}>
                                                                                <option>Select your card</option>
                                                                                {cardList.map((res) => (
                                                                                    paymentFor === 'telcos' || res.Type === 'Debit Card'  ? (
                                                                                    <option 
                                                                                        value={[res.Name,
                                                                                            res.Issuer,
                                                                                            res.Type,
                                                                                            res.cardNum,
                                                                                            res.Amount]} 
                                                                                        onChange={changeSelect}>
                                                                                        {res.Type} | {res.Name} | {res.cardNum}
                                                                                    </option>
                                                                                    ) : null
                                                                                        ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                        <Button style ={walletButtonStyle} variant="primary" onClick={() => handleSubmit('Payment - Credit Card')}>
                                                                            Submit
                                                                        </Button>
                                                                    </Form>
                                                                </Card.Body>
                                                            </Card>
                                                        </Tab.Pane>
                                                        {/* Payment - Telcos */}
                                                        <Tab.Pane eventKey="payment-telcos">
                                                            <Card style ={paymentStyle}>
                                                                <Card.Title>Telcos</Card.Title>
                                                                <Card.Body>
                                                                    <Form>
                                                                        <Form.Group className="mb-3 d-flex" controlId="mobileNo">
                                                                            <Form.Label className='d-flex align-items-center'>Mobile No.</Form.Label>&nbsp;
                                                                            <Form.Control type="number" name="MNO" value={topup.MNO} onChange={changeTopup} placeholder="Enter mobile no" />&nbsp;                                                       
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3 d-flex" controlId="creditAmount">
                                                                            <Form.Label className='d-flex align-items-center'>Amount</Form.Label>&nbsp;
                                                                            <Form.Control type="number" name="Amount" value={topup.Amount} onChange={changeTopup} placeholder="Enter amount" />&nbsp;                                                  
                                                                        </Form.Group>
                                                                        <Form.Group className="mb-3 d-flex" controlId="creditCardNum">
                                                                            <Form.Label className='d-flex align-items-center'>Select Card</Form.Label>&nbsp;
                                                                            <Form.Select aria-label="Default select example" onChange={changeSelect}>
                                                                                <option>Select your card</option>
                                                                                {cardList.map((res) => (
                                                                                    <option 
                                                                                        value={[res.Name,
                                                                                            res.Issuer,
                                                                                            res.Type,
                                                                                            res.cardNum,
                                                                                            res.Amount]} 
                                                                                        onChange={changeSelect}>
                                                                                        {res.Type} | {res.Name} | {res.cardNum}
                                                                                    </option>
                                                                                        ))}
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                        <Button style ={walletButtonStyle} variant="primary" onClick={() => handleSubmit('Payment - Telcos')}>
                                                                            Submit
                                                                        </Button>
                                                                    </Form>
                                                                </Card.Body>
                                                            </Card>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            {/* End Payment Home */}
                            {/* Transfer Home */}
                            <Tab.Pane eventKey="third">
                                <Card style={transferStyle}>
                                    <Card.Title>Transfer <sub>(to)</sub></Card.Title>
                                    <Card.Body>
                                        <Form>
                                            <Form.Group className="mb-3 d-flex" controlId="Recipient">
                                                <Form.Label className='d-flex align-items-center'>Recipient</Form.Label>&nbsp;
                                                <Form.Select aria-label="Default select example" name='Recipient' onChange={changeSelect2}>
                                                    <option>Please select recipient</option>
                                                    <option value="Ikmal" onChange={changeSelect2}>Ikmal</option>
                                                    <option value="Munana" onChange={changeSelect2}>Munana</option>
                                                    <option value="Amaninajwa" onChange={changeSelect2}>amaninajwa</option>
                                                    <option value="WARS" onChange={changeSelect2}>WARS</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3 d-flex" controlId="creditAmount">
                                                <Form.Label className='d-flex align-items-center'>Amount</Form.Label>&nbsp;
                                                <Form.Control type="number" name="Amount" value={topup.Amount} onChange={changeTopup} placeholder="Enter amount" />       
                                            </Form.Group>
                                            <Form.Group className="mb-3 d-flex" controlId="creditCardNum">
                                                <Form.Label className='d-flex align-items-center'>Select Card</Form.Label>&nbsp;
                                                <Form.Select aria-label="Default select example" onChange={changeSelect}>
                                                    <option>Select your card</option>
                                                    {cardList.map((res) => (
                                                        res.Type === 'Topup Card'  ? (
                                                        <option 
                                                            value={[res.Name,
                                                                res.Issuer,
                                                                res.Type,
                                                                res.cardNum,
                                                                res.Amount]} 
                                                            onChange={changeSelect}>
                                                            {res.Type} | {res.Name} | {res.cardNum}
                                                        </option>
                                                        ) : null
                                                            ))}
                                                </Form.Select>
                                            </Form.Group>
                                            <Button style ={walletButtonStyle}  variant="primary" onClick={() => handleSubmit('Transfer')}>
                                                Submit
                                            </Button>
                                        </Form>
                                        
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            {/* End Transfer Home */}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

            <hr />

            {/* Wallet Transactions History */}
            <ul className='walletHistory'>
            {history.length > 0 ? history.reverse().map((res) => (
                <li>
                    <Row>
                <Col>
                    <Container>
                        <Row>
                            <div className="col-m0">
                                <Card style={historyStyle}>
                                    <Card.Body className="d-flex">
                                        <Card.Img className="icon1" variant="top" src={require('../assets/logo2.png')} style={{width: 'unset', height: '70px'}}/>
                                        <div className="container-fluid">
                                            <Card.Title>{res.Name}</Card.Title>
                                            <Card.Text><i>{res.Type}</i></Card.Text>
                                        </div>
                                        <h3>{
                                            res.Target === 'Wallet' ? 
                                            <p className='greenline'>+${res.Amount}</p> : 
                                            <p className='redline'>-${res.Amount}</p>
                                        }</h3>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </Col>
            </Row>
                </li>
           )): <Row>
           <Col>
               <Container>
                   <Row>
                       <div className="col-m0">
                           <Card style={historyStyle}>
                               <Card.Body className="d-flex" style ={{display: "flex", justifyContent: "center", alignItems: "center", fontStyle: "italic"}}>
                                   There is no transaction made recently
                               </Card.Body>
                           </Card>
                       </div>
                   </Row>
               </Container>
           </Col>
                </Row>}
            </ul>
            {/* End Wallet Transactions History */}

            {/* Loader Modal */}
            <Modal show={show} onHide={handleClose} centered className='loader'>
                <Modal.Body className='d-flex'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>&nbsp;<Modal.Title>Authenticating. Please Wait.</Modal.Title>
                </Modal.Body>
            </Modal>
      {/* Loader Modal Ends */}
        </Container>
        </>
        
        
    );
}

export default Dashboard;
import React from 'react';
import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const UserSidebarData = [
  {
    title: 'Profile',
    path: '/UserProfile',
    icon: <FaIcons.FaUserCircle/>
  },
  {
    title: 'Leave',
    // path: '/Leave',
    icon: <FaIcons.FaRegFile />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Leave Request',
        path: '/leave/leaveRequest',
        icon: <IoIcons.IoIosPaper />
      },
      {
        title: 'Leave Dashboard',
        path: '/leave/Leavedashboard',
        icon: <IoIcons.IoIosPaper />
      }
    ]
  },
  {
    title: 'Claim',
    // path: '/claim',
    icon: <FaIcons.FaRegFile />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Claim Request',
        path: '/claim/claimrequest',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      },
      {
        title: 'Claim Dashboard',
        path: '/claim/claimdashboard',
        icon: <IoIcons.IoIosPaper />,
        cName: 'sub-nav'
      }
    ]
  },
  {
    title: 'Logout',
    path: '/SignIn',
    icon: <FaIcons.FaSignOutAlt />
  }
];
